import fetchOld from '../js/fetchToken'

const fetch = (data, autoGetToken = false) => {
  if (!autoGetToken) {
    return fetchOld(data)
  } else {
    if (!data.data) {
      data.data = {};
    }
    if (!data.params) {
      data.params = {}
    }
    data.params.token = JSON.parse(localStorage.getItem('userInfo')).token;
    data.data.token = JSON.parse(localStorage.getItem('userInfo')).token;
    return fetchOld(data)
  }
}

// 获取活动榜单
export const activeRankList = (params = {}) => {
	return fetch({
		url: '/live/rank/activeRankList',
		params
	})
}