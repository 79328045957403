<template>
	<div class="content">
		<div class="listBox">
			<div class="listTop">
				<p class="listDetails" @click="clickDetails('zh_CN')">活动说明</p>
				<p class="listDetailsEN" @click="clickDetails('en_US')">Activity Description</p>
				<div class="listTopBox">
					<div class="listTopThree">
						<div class="listTopThreeBox">
							<div class="listTopThreeItem">
								<img class="listRanking" src="../../assets/img/list/two.png" alt="two" />
								<img class="listPhoto1" :src="topThreeList[1] ? topThreeList[1].avatar : defaultAvatar" alt="头像" />
								<p class="listName">{{ topThreeList[1] ? topThreeList[1].uname : ' ' }}</p>
								<p class="listNumber">{{ topThreeList[1] ? topThreeList[1].peas : ' ' }}</p>
							</div>
							<div class="listTopThreeItem">
								<img class="listRanking" src="../../assets/img/list/one.png" alt="two" />
								<img class="listPhoto2" :src="topThreeList[0] ? topThreeList[0].avatar : defaultAvatar" alt="头像" />
								<p class="listName">{{ topThreeList[0] ? topThreeList[0].uname : ' ' }}</p>
								<p class="listNumber">{{ topThreeList[0] ? topThreeList[0].peas : ' ' }}</p>
							</div>
							<div class="listTopThreeItem">
								<img class="listRanking" src="../../assets/img/list/three.png" alt="two" />
								<img class="listPhoto3" :src="topThreeList[2] ? topThreeList[2].avatar : defaultAvatar" alt="头像" />
								<p class="listName">{{ topThreeList[2] ? topThreeList[2].uname : ' ' }}</p>
								<p class="listNumber">{{ topThreeList[2] ? topThreeList[2].peas : ' ' }}</p>
							</div>
						</div>
						<img class="topThreeImg" src="../../assets/img/list/topThree.png" alt="topThree" />
					</div>
				</div>
			</div>
			<div class="listBottom">
				<div class="listBottomBox">
					<div class="listBottomItem" v-for="(item, index) in lastList" :key="index">
						<div class="listBottomLeft">
							<p class="listBottomOrder">{{ index + 4 }}</p>
							<img class="listBottomPhoto" :src="item.avatar" alt="头像" />
							<p class="listBottomName">{{ item.uname }}</p>
						</div>
						<p class="listBottomNumber">{{ item.peas }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HB from "@/assets/js/bridge";
	import { activeRankList } from "../../assets/api/listApi.js";
	
	export default {
		data() {
			return {
				token: '',
				defaultAvatar: require("../../assets/img/defaultAvatar.png"),
				topThreeList: [],
				lastList: []
			}
		},
		created() {
			
		},
		mounted() {
			this.token = this.$route.query.token;
			this.activeRankList();
		},
		methods: {
			// 获取活动榜单
			activeRankList() {
				activeRankList({
					
				}).then((res) => {
					console.log(res, '获取活动榜单');
					if(res.code == 0) {
						this.topThreeList = res.data.slice(0, 3);
						this.lastList = res.data.slice(3);
					} else {
						this.Toast(res.msg);
					}
				})
			},
			// 跳转活动说明
			clickDetails(language) {
				this.$router.push({
					path: '/listDetails',
					query: {
						language: language
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		width: 100%;
		min-height: 100%;
		background-color: #111118;
	}
	
	.listBox {
		width: 100%;
	}
	.listDetails {
		font-size: 0.3rem;
		font-weight: bold;
		color: #FFFFFF;
		position: absolute;
		top: 0.4rem;
		right: 0.4rem;
	}
	.listDetailsEN {
		font-size: 0.3rem;
		font-weight: bold;
		color: #FFFFFF;
		position: absolute;
		top: 1rem;
		right: 0.4rem;
	}
	.listTop {
		width: 100%;
		height: 6.5rem;
		background-image: url('../../assets/img/list/listTopBg.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		.listTopBox {
			width: 7.32rem;
			margin: 0 auto;
			.listTopThree {
				position: absolute;
				bottom: 0;
				.listTopThreeBox {
					width: 92%;
					margin: 0 auto;
					position: absolute;
					left: 4%;
					bottom: 1.5rem;
					display: flex;
					justify-content: space-between;
					.listTopThreeItem {
						width: 2.18rem;
						margin-top: 0.08rem;
						text-align: center;
						.listRanking {
							display: block;
							width: 0.38rem;
							height: 0.28rem;
							margin: 0 auto;
						}
						.listPhoto1 {
							display: block;
							width: 1.44rem;
							height: 1.44rem;
							margin: 0 auto;
							border-radius: 50%;
							border: 0.04rem solid #C7D0E0;
						}
						.listPhoto2 {
							display: block;
							width: 1.64rem;
							height: 1.64rem;
							margin: 0 auto;
							border-radius: 50%;
							border: 0.04rem solid #F5CD72;
						}
						.listPhoto3 {
							display: block;
							width: 1.44rem;
							height: 1.44rem;
							margin: 0 auto;
							border-radius: 50%;
							border: 0.04rem solid #E4C4AB;
						}
						.listName {
							height: 0.6rem;
							font-size: 0.3rem;
							font-weight: 600;
							color: #FFFFFF;
							line-height: 0.6rem;
							overflow: hidden; 
							text-overflow: ellipsis;
							display: -webkit-box; 
							word-break: break-all;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1; 
						}
						.listNumber {
							height: 0.48rem;
							font-size: 0.24rem;
							font-weight: 400;
							color: #F3F3F3;
						}
					}
					.listTopThreeItem:first-child {
						margin-top: 0.6rem;
					}
					.listTopThreeItem:last-child {
						margin-top: 0.6rem !important;
					}
				}
				.topThreeImg {
					display: block;
					width: 7.32rem;
					height: 2.04rem;
				}
			}
		}
	}
	.listBottom {
		width: 100%;
		min-height: 3rem;
		background-color: #111118;
		border-top-left-radius: 0.3rem;
		border-top-right-radius: 0.3rem;
		position: relative;
		margin-top: -0.38rem;
		.listBottomBox {
			width: 90%;
			margin: 0 auto;
			.listBottomItem {
				width: 100%;
				padding-top: 0.3rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				div {
					display: flex;
					align-items: center;
				}
			}
			.listBottomOrder {
				width: 0.34rem;
				font-size: 0.32rem;
				font-weight: 600;
				color: #C5C5D1;
			}
			.listBottomPhoto {
				display: block;
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 50%;
				margin-left: 0.32rem;
			}
			.listBottomName {
				font-size: 0.3rem;
				font-weight: 500;
				color: #C5C5D1;
				margin-left: 0.22rem;
			}
			.listBottomNumber {
				font-size: 0.24rem;
				font-weight: 400;
				color: #686872;
			}
		}
	}
</style>